import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import kebabCaseIcon from 'utils/kebab-case-icon';
import { Container } from 'components/layout';
import Leader from 'components/leader';

import PricingSubsection from '../pricing-subsection';
import Products from '../products';
import { ProductCardAdditional } from '../product-card';
import PlanCard, { PlanCardGrid } from '../plan-card';
import Features from '../features';

import PricingToggle from './PricingToggle';
import s from './PricingSection.scss';

const PlanPropType = PropTypes.shape({
  heading: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  price: PropTypes.number,
  priceSuffix: PropTypes.string,
  bulkPrice: PropTypes.number,
  bulkPriceSuffix: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
});

export default class PricingSection extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    anchor: PropTypes.string,
    hasBulkPricing: PropTypes.bool,
    bulkPricingLabel: PropTypes.string,
    standardPricingLabel: PropTypes.string,
    productsHeading: PropTypes.string,
    productsCardStyle: PropTypes.oneOf(['Small card', 'Wide, detail card']),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        price: PropTypes.number,
        priceSuffix: PropTypes.string,
        bulkPrice: PropTypes.number,
        bulkPriceSuffix: PropTypes.string,
        ctaLink: PropTypes.string,
        ctaText: PropTypes.string,
      }),
    ),
    plansHeading: PropTypes.string,
    plansText: PropTypes.string,
    plans: PropTypes.arrayOf(PlanPropType),
    bulkPlans: PropTypes.arrayOf(PlanPropType),
    features: PropTypes.array,
    bulkFeatures: PropTypes.array,
    additionalHeading: PropTypes.string,
    additionalText: PropTypes.string,
    additionalReferences: PropTypes.array,
  }

  state = {
    activePlan: this.props.hasBulkPricing ? 0 : 1,
    featuresVisible: false,
  }

  selectPlan = (i) => {
    this.setState({
      activePlan: i,
    });
  }

  toggleFeatures = () => {
    this.setState({
      featuresVisible: !this.state.featuresVisible,
    });
  }

  render() {
    const {
      heading,
      text,
      anchor,
      hasBulkPricing,
      bulkPricingLabel,
      standardPricingLabel,
      productsHeading,
      productsCardStyle,
      products,
      plansHeading,
      plansText,
      plans,
      bulkPlans,
      features,
      bulkFeatures,
      additionalHeading,
      additionalText,
      additionalReferences,
    } = this.props;

    const { activePlan, featuresVisible } = this.state;
    const showBulkPrice = activePlan === 0;
    const visiblePlans = showBulkPrice ? bulkPlans : plans;
    const visibleFeatures = showBulkPrice ? bulkFeatures : features;

    return (
      <div className={s.pricing} id={anchor}>
        <Leader centered heading={heading} headingTag="h2" text={text} />
        {hasBulkPricing &&
          <div className={s.pricing__toggle}>
            <PricingToggle
              bulkLabel={bulkPricingLabel}
              standardLabel={standardPricingLabel}
              onClick={this.selectPlan}
              activeIndex={activePlan}
            />
          </div>
        }
        <Container>
          {products &&
            <PricingSubsection heading={productsHeading}>
              <Products
                products={products}
                wideCardStyle={productsCardStyle === 'Wide, detail card'}
                showBulkPrice={showBulkPrice}
              />
            </PricingSubsection>
          }
          {plans &&
            <PricingSubsection heading={plansHeading} text={plansText}>
              <PlanCardGrid>
                {visiblePlans.map(plan => (
                  <PlanCard
                    key={plan.name}
                    heading={plan.name}
                    icon={plan.icon && kebabCaseIcon(plan.icon)}
                    text={_get(plan, 'description.description')}
                    price={plan.price}
                    priceSuffix={plan.priceSuffix}
                    link={plan.ctaLink}
                    ctaText={plan.ctaText}
                  />
                ))}
              </PlanCardGrid>
              {features &&
                <Features
                  tables={[
                    'Features',
                    'Admin Support',
                    'SLA',
                    'Developer Support',
                    'Installation Support',
                    'Add-On Feature',
                  ]}
                  features={visibleFeatures.map(feature => ({
                    feature: feature.feature,
                    category: feature.category,
                    availability: feature.availability.map(avail => avail.name),
                  }))}
                  visible={featuresVisible}
                  visiblePlans={visiblePlans}
                  onToggle={this.toggleFeatures}
                />
              }
            </PricingSubsection>
          }
          {additionalReferences &&
            <PricingSubsection heading={additionalHeading} text={additionalText}>
              {additionalReferences.map(product => (
                <ProductCardAdditional
                  key={product.name}
                  heading={product.name}
                  text={_get(product, 'description.description')}
                  cta={product.primaryCtaLink && {
                    url: product.primaryCtaLink,
                    text: product.primaryCtaText || 'Learn more',
                  }}
                  price={showBulkPrice ? (product.bulkPrice || product.price) : product.price}
                  priceSuffix={product.priceSuffix}
                  image={_get(product.images[0], 'file.url')}
                />
              ))
              }
            </PricingSubsection>
          }
        </Container>
        <hr className={s.pricing__hr} />
      </div>
    );
  }
}
