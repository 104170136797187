import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './ProductGallery.scss';
import ProductGalleryNav from './ProductGalleryNav';

export default class ProductGallery extends Component {

  state = {
    activeImage: 0,
  }

  static propTypes = {
    images: PropTypes.array,
  }

  update = (i) => {
    this.setState({
      activeImage: i,
    });
  }

  render() {
    const { images } = this.props;

    const currentImage = images[this.state.activeImage];

    return (
      <div className={s.productGallery}>
        <div className={s.productGallery__nav}>
          <ProductGalleryNav images={images} onUpdate={this.update} />
        </div>

        <div className={s.productGallery__imageWrap}>
          <div className={s.productGallery__image}>
            <Image src={currentImage.file.url} transition="none" />
          </div>
        </div>

      </div>
    );
  }
}
