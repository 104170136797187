import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import _get from 'lodash/get'
import LazyLoad from 'react-lazyload'

import extractMeta from 'utils/extract-meta'
import kebabCaseIcon from 'utils/kebab-case-icon'
import GreyArea from 'components/grey-area'
import Hero from 'components/hero'
import Modules from 'containers/modules'

import Start from './components/start'
import Contents from './components/contents'
import PricingSection from './components/pricing-section'
import SEO from 'components/seo'

export default class Pricing extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const { page } = this.props.data
    const { pathname } = this.props.location

    return (
      <div>
        <SEO
          {...extractMeta(page)}
          article
          htmlAttributes={{ class: 'smooth-scroll' }}
        />
        <Hero
          centered
          theme={page.heroTheme}
          heading={page.heroHeading}
          text={page.heroText}
          poster={_get(page.heroImage, 'file.url')}
          circles={page.heroCirclesAnimation}
        />

        <GreyArea>
          <Start
            links={[
              {
                icon: kebabCaseIcon(page.iconExisting),
                heading: page.headingExisting,
                ctaText: page.viewPricingCta,
                kind: 'existing',
              },
              {
                icon: kebabCaseIcon(page.iconNew),
                heading: page.headingNew,
                ctaText: page.viewPricingCta,
                kind: 'new',
              },
            ]}
          />

          <LazyLoad once offset={500}>
            <Contents
              heading={page.tocHeading}
              text={_get(page.tocText, 'text')}
              sections={page.pricing.map(section => ({
                kicker: _get(section, 'teaserEyebrow'),
                heading: _get(section, 'teaserHeading'),
                text: _get(section, 'teaserText'),
                anchor: _get(section, 'anchor'),
                cta: {
                  text: _get(section, 'teaserCtaText'),
                  url: _get(section, 'anchor'),
                },
              }))}
            />
          </LazyLoad>

          {page.pricing.map(section => (
            <LazyLoad key={_get(section, 'heading')} once offset={500}>
              <PricingSection
                key={_get(section, 'heading')}
                heading={_get(section, 'heading')}
                text={_get(section, 'text.text')}
                anchor={_get(section, 'anchor')}
                teaserHeading={_get(section, 'teaserHeading')}
                teaserText={_get(section, 'teaserText')}
                teaserCtaText={_get(section, 'teaserCtaText')}
                hasBulkPricing={_get(section, 'hasBulkPricing')}
                bulkPricingLabel={_get(section, 'bulkPricingLabel')}
                standardPricingLabel={_get(section, 'standardPricingLabel')}
                productsHeading={_get(section, 'productsHeading')}
                productsCardStyle={_get(section, 'productsCardStyle')}
                products={_get(section, 'products')}
                plansHeading={_get(section, 'plansHeading')}
                plansText={_get(section, 'plansText')}
                plans={_get(section, 'plans')}
                bulkPlans={_get(section, 'bulkPlans')}
                features={_get(section, 'features')}
                bulkFeatures={_get(section, 'bulkFeatures')}
                additionalHeading={_get(section, 'additionalHeading')}
                additionalText={_get(section, 'additionalText')}
                additionalReferences={_get(section, 'additionalReferences')}
              />
            </LazyLoad>
          ))}

          <Modules list={[page.callToAction]} />
        </GreyArea>
      </div>
    )
  }
}
