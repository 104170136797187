import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';
import Leader from 'components/leader';

import PlanCard, { PlanCardGrid } from '../plan-card';

import s from './Contents.scss';

export default class Contents extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        kicker: PropTypes.string,
        heading: PropTypes.string,
        text: PropTypes.string,
        ctaAnchor: PropTypes.string,
      }),
    ),
  }

  render() {
    const { heading, text, sections } = this.props;

    return (
      <div className={s.contents}>
        <Leader
          centered
          heading={heading}
          text={text}
        />
        <Container>
          <PlanCardGrid>

            {sections.map((section, i) => (
              <PlanCard
                // eslint-disable-next-line react/no-array-index-key
                key={`${s.contents}-${i}`}
                heading={section.heading}
                kicker={section.kicker}
                text={section.text}
                link={`#${section.anchor}`}
                ctaText={section.cta.text}
                className={s.contents__priceCard}
              />
            ))}

          </PlanCardGrid>
        </Container>
        <hr className={s.contents__hr} />
      </div>
    );
  }
}
