import { graphql } from 'gatsby';
import Pricing from 'routes/pricing/Pricing';

export default Pricing;

export const query = graphql`
  query Pricing {
    page: contentfulPagePricing {
      # Meta info
      pageTitle
      metaTitle
      metaDescription
      metaImage {
        ...image
      }

      # Pricing calculator CTA
      viewPricingCta

      # Hero
      heroHeading: heroTitle
      heroText: heroBody
      heroTheme
      heroCirclesAnimation
      heroImage {
        ...image
      }

      # Calculator
      iconExisting: calculatorIconExisting
      headingExisting: calculatorTitleExisting
      iconNew: calculatorIconNew
      headingNew: calculatorTitleNew

      # Table of contents section
      tocHeading: tableOfContentsTitle
      tocText: tableOfContentsBody {
        text: tableOfContentsBody
      }
      pricing {
        ...pricingModule
      }
      callToAction {
        ...moduleList
      }
    }
  }

  fragment plan on ContentfulPlanPricing {
    id
    name
    icon
    description {
      description
    }
    price
    priceSuffix
    bulkPrice
    bulkPriceSuffix
    ctaText
    ctaLink
  }

  fragment product on ContentfulProduct {
    name
    description {
      description
    }
    features
    price
    priceSuffix
    bulkPrice
    bulkPriceSuffix
    eyebrowText
    primaryCtaText
    primaryCtaLink
    secondaryCtaText
    secondaryCtaLink
    images {
      ...image
    }
  }

  fragment feature on ContentfulFeature {
    feature
    category
    availability {
      id
      name
    }
  }

  fragment pricingModule on ContentfulModulePricing {
    heading: title
    text: body {
      text: body
    }
    anchor
    teaserEyebrow
    teaserHeading: teaserTitle
    teaserText: teaserBody
    teaserCtaText
    hasBulkPricing
    bulkPricingLabel
    standardPricingLabel
    productsHeading: productsTitle
    productsCardStyle
    products {
      ...product
    }
    plansHeading: plansTitle
    plansText: plansBody
    plans {
      ...plan
    }
    bulkPlans {
      ...plan
    }
    features {
      ...feature
    }
    bulkFeatures {
      ...feature
    }
    additionalHeading: additionalTitle
    additionalText: additionalBody
    additionalReferences {
      ...product
    }
  }
`;
