import React, { Children } from 'react';
import PropTypes from 'prop-types';

import s from './PlanCardGrid.scss';

const PlanCardGrid = ({ children }) => {

  const childCount = Children.count(children);

  return (

    <div className={s('planCardGrid', `count${childCount}`)}>
      <ul className={s.planCardGrid__row}>
        {Children.map(children, (child, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${s.planCardGrid}-${i}`} className={s.planCardGrid__item}>
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
};

PlanCardGrid.propTypes = {
  children: PropTypes.node,
};

export default PlanCardGrid;
