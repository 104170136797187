import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _union from 'lodash/union';
import _flatten from 'lodash/flatten';

import FeaturesTableRow from './FeaturesTableRow';

import s from './FeaturesTable.scss';

export default class FeaturesTable extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    items: PropTypes.array,
    visible: PropTypes.bool,
    visiblePlans: PropTypes.array,
  }

  static defaultProps = {
    heading: undefined,
    items: [],
  }

  render() {
    const { heading, items, visible, visiblePlans } = this.props;

    const columns = _union(
      _flatten(
        items.map(item => item.availability),
      ),
    )
      // Only display columns matching the currently visible set of plans
      // There are two sets of plans: regular and bulk (enterprise), but we don't want the table to
      // show both sets at once
      .filter(
        column => visiblePlans.findIndex(plan => plan.name === column) > -1,
      );

    if (!columns || columns.length === 0 || !visible) return null;

    return (
      <div className={s.featuresTable}>

        <table className={s.featuresTable__table}>
          <thead>
            <tr>
              <th className={s.featuresTable__heading}>{heading}</th>
              {columns.map(column => (
                <th key={column}>{column}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <FeaturesTableRow
                key={item.feature}
                heading={item.feature}
                availability={columns.map(col => (
                  item.availability.includes(col)
                  ))}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
