import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Icon from 'components/icon';
import Price from 'components/price';

import s from './PlanCard.scss';

const PlanCard = (
  { heading,
    kicker,
    text,
    ctaText,
    link,
    icon,
    price,
    priceSuffix,
    className },
) => (
  <div className={s('planCard', className)}>
    {icon && (
      <div className={s.planCard__icon}>
        <Icon id={icon} />
      </div>
    )}
    <h2 className={s.planCard__heading}>{heading}</h2>
    {kicker && <h3 className={s.planCard__kicker}>{kicker}</h3>}
    <p className={s.planCard__text}>{text}</p>

    {price && (
      <div className={s.planCard__price}>
        <Price centered amount={price} suffix={priceSuffix} />
      </div>
    )}

    {link && (
      <div className={s.planCard__button}>
        <Button color="borderRed" to={link}>{ctaText}</Button>
      </div>
    )}
  </div>
);

PlanCard.propTypes = {
  heading: PropTypes.string,
  kicker: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
  price: PropTypes.number,
  priceSuffix: PropTypes.string,
  className: PropTypes.string,
};

export default PlanCard;
