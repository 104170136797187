import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';
import Image from 'components/image';
import Price from 'components/price';

import s from './ProductCardAdditional.scss';

export default class ProductCardAdditional extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    cta: PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    }),
    price: PropTypes.number,
    priceSuffix: PropTypes.string,
    image: PropTypes.string,
  }

  static defaultProps = {
    heading: null,
    text: null,
    cta: {},
    price: null,
    priceSuffix: null,
    image: null,
  }

  render() {
    const {
      heading,
      text,
      cta,
      price,
      priceSuffix,
      image,
    } = this.props;

    return (
      <div className={s.productAdditional}>
        <div className={s.productAdditional__detail}>
          <div className={s.productAdditional__imageWrap}>
            {image &&
              <div className={s.productAdditional__image}>
                <Image src={image} transition="none" />
              </div>
            }
          </div>

          <div className={s.productAdditional__content}>

            <div className={s.productAdditional__contentInner}>
              {heading && <h3 className={s.productAdditional__heading}>{heading}</h3>}
              {text && <p className={s.productAdditional__text}>{text}</p>}
              {cta && <Clickable to={cta.url}>{cta.text}</Clickable>}
            </div>

            <div className={s.productAdditional__price}>
              <Price centered amount={price} suffix={priceSuffix} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
