import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Button from 'components/button';
import Image from 'components/image';
import Price from 'components/price';

import s from './ProductCard.scss';

const ProductCard = ({ image, kicker, heading, text, price, priceSuffix, link, ctaText }) => (
  <div className={s.productCard}>
    <div className={s.productCard__imageWrap}>
      {image &&
        <div className={s.productCard__image}>
          <Image src={_get(image, 'file.url')} transition="none" />
        </div>
      }
    </div>
    <div className={s.productCard__content}>
      <h3 className={s.productCard__heading}>{heading}</h3>
      <h4 className={s.productCard__kicker}>{kicker}</h4>
      <p className={s.productCard__text}>{text}</p>
    </div>

    <div className={s.productCard__price}>
      <Price amount={price} suffix={priceSuffix} />
    </div>

    <div className={s.productCard__cta}>
      <Button to={link}>{ctaText}</Button>
    </div>
  </div>
);

ProductCard.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  kicker: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  price: PropTypes.number,
  priceSuffix: PropTypes.string,
  link: PropTypes.string,
  ctaText: PropTypes.string,
};

export default ProductCard;
