import React from 'react';
import PropTypes from 'prop-types';

import s from './ProductGalleryNav.scss';

const ProductGalleryNav = ({ images, onUpdate }) => (
  <ol className={s.productGalleryNav}>
    {images.map((image, i) => {
      const { file } = image;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`${s.productGalleryNav__navItem}-${i}`} className={s.productGalleryNav__item}>
          <button
            className={s.productGalleryNav__button}
            style={{ backgroundImage: `url('${file.url}')` }}
            onClick={() => { onUpdate(i); }}
          >
            <span className={s.productGalleryNav__buttonText}>{file.descrption || i}</span>
          </button>
        </li>
        );
    })}
  </ol>
);

ProductGalleryNav.propTypes = {
  images: PropTypes.array,
  onUpdate: PropTypes.func,
};

export default ProductGalleryNav;
