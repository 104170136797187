import React from 'react';
import PropTypes from 'prop-types';

import s from './PricingSubsection.scss';

const PricingSubsection = ({ heading, text, children }) => (
  <div className={s.pricingSubsection}>
    {heading &&
      <div className={s.pricingSubsection__leader}>
        <h3 className={s.pricingSubsection__heading}>{heading}</h3>
        {text && <p className={s.pricingSubsection__text}>{text}</p>}
      </div>
    }
    {children}
  </div>
);

PricingSubsection.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
};

export default PricingSubsection;

