import React from 'react';
import PropTypes from 'prop-types';

import Check from 'assets/svg/icons/check.svg';

const FeaturesTableRow = ({ heading, availability }) => (
  <tr>
    <td>{heading}</td>
    {availability.map((avail, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <td key={avail + i}>{avail && <Check />}</td>
    ))}
  </tr>
);

FeaturesTableRow.propTypes = {
  heading: PropTypes.string,
  availability: PropTypes.array,
};

FeaturesTableRow.defaultProps = {
  heading: undefined,
  availability: [],
};

export default FeaturesTableRow;

