import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import { Row } from 'components/layout';
import Price from 'components/price';

import { ProductGallery } from '.';

import s from './ProductContent.scss';

export default class ProductContent extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    images: PropTypes.array,
    features: PropTypes.array,
    price: PropTypes.number,
    primaryLink: PropTypes.string,
    primaryLinkText: PropTypes.string,
    secondaryLink: PropTypes.string,
    secondaryLinkText: PropTypes.string,
  }

  render() {
    const {
      heading,
      text,
      images,
      features,
      price,
      primaryLink,
      primaryLinkText,
      secondaryLink,
      secondaryLinkText,
    } = this.props;

    return (
      <div className={s.productContent}>
        <Row>
          <div className={s.productContent__gallery}>
            <ProductGallery images={images} />
          </div>
          <div className={s.productContent__content}>
            <div className={s.productContent__contentInner}>
              <h2 className={s.productContent__heading}>{heading}</h2>
              <p className={s.productContent__text}>{text}</p>

              <div className={s.productContent__features}>
                <h3 className={s.productContent__subheading}>Features</h3>
                <ul className={s.productContent__list}>
                  {features.map((feature, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${s.features}-${i}`}>{feature}</li>
                  ))}
                </ul>

                <div className={s.productContent__price}>
                  <Price amount={price} />
                </div>

                <div className={s.productContent__cta}>
                  <div className={s.productContent__link}>
                    <Button to={primaryLink}>{primaryLinkText}</Button>
                  </div>
                  <div className={s.productContent__link}>
                    <Button color="transparent" to={secondaryLink}>{secondaryLinkText}</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}
