import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './PricingToggle.scss';

export default class PricingToggle extends Component {

  static propTypes = {
    bulkLabel: PropTypes.string,
    standardLabel: PropTypes.string,
    onClick: PropTypes.func,
    activeIndex: PropTypes.number,
  }

  render() {
    const { bulkLabel, standardLabel, onClick, activeIndex } = this.props;

    return (
      <div className={s.toggle}>
        <button
          onClick={() => { onClick(0); }}
          className={s('toggle__label', { active: activeIndex === 0 })}
        >
          {bulkLabel}
        </button>
        <button
          onClick={() => { onClick(activeIndex === 0 ? 1 : 0); }}
          className={s('toggle__button', { right: activeIndex === 1 })}
        >
          <span className={s.toggle__buttonText}>change</span>
        </button>
        <button
          onClick={() => { onClick(1); }}
          className={s('toggle__label', { active: activeIndex === 1 })}
        >
          {standardLabel}
        </button>
      </div>
    );
  }
}
