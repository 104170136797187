import React from 'react';
import PropTypes from 'prop-types';

import Chevron from 'assets/svg/icons/chevron.svg';

import FeaturesTable from './FeaturesTable';

import s from './Features.scss';

const tables = [
  'Features',
  'Admin Support',
  'SLA',
  'Developer Support',
  'Installation Support',
  'Add-On Feature',
];

const Features = ({ features, visible, children, onToggle, visiblePlans }) => {

  const buttonText = visible ? 'Hide features' : 'Show features';

  return (
    <div className={s.features}>
      {tables.map(table => (
        <FeaturesTable
          key={table}
          heading={table}
          items={features.filter(feature => feature.category === table)}
          visible={visible}
          visiblePlans={visiblePlans}
        />
      ))}

      <div className={s.features__buttonWrap}>
        <button className={s('features__button', { visible })} onClick={onToggle}>
          {buttonText} <Chevron className={s.features__chevron} />
        </button>
      </div>

      {children}
    </div>
  );

};

Features.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      feature: PropTypes.string,
      category: PropTypes.oneOf(tables),
      availability: PropTypes.array,
    }),
  ),
  visiblePlans: PropTypes.array,
  children: PropTypes.node,
  visible: PropTypes.bool,
  onToggle: PropTypes.func,
};

Features.defaultProps = {
  features: [],
  children: undefined,
};

export default Features;
