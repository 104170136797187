import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Icon from 'components/icon';

import s from './StartCard.scss';

const StartCard = ({ icon, heading, ctaText, url }) => (
  <div className={s.startCard}>
    <div className={s.startCard__content}>
      <div className={s.startCard__icon}>
        <Icon id={icon} />
      </div>
      <h2 className={s.startCard__heading}>{heading}</h2>
      <div className={s.startCard__button}>
        <Button color="transparent" to={url}>{ctaText}</Button>
      </div>
    </div>
  </div>
);

StartCard.propTypes = {
  icon: PropTypes.string,
  heading: PropTypes.string,
  ctaText: PropTypes.string,
  url: PropTypes.string,
};

export default StartCard;
