import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';

import { StartCard } from '.';

import s from './Start.scss';

export default class Start extends PureComponent {

  static propTypes = {
    links: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        heading: PropTypes.string,
        ctaText: PropTypes.string,
        kind: PropTypes.oneOf(['new', 'existing']),
      }),
    ),
  }

  render() {

    const { links } = this.props;

    return (
      <div className={s.start}>
        <Container>
          <div className={s.start__inner}>
            {links.map((link, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${s.start}-${i}`} className={s.start__item}>
                <StartCard
                  icon={link.icon}
                  heading={link.heading}
                  ctaText={link.ctaText}
                  url={`/pricing/calculate?kind=${link.kind}`}
                />
              </div>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}
