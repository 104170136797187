import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { Row } from 'components/layout';
import { ProductContent } from '.';
import ProductCard from '../product-card';

import s from './Products.scss';

const Products = ({ products, wideCardStyle, showBulkPrice }) => (
  <div className={s.products}>
    {wideCardStyle ? (
      products.map(product => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={s.products__content} key={product.name}>
          <ProductContent
            heading={product.name}
            text={product.description.description}
            price={showBulkPrice ? (product.bulkPrice || product.price) : product.price}
            primaryLink={product.primaryCtaLink}
            primaryLinkText={product.primaryCtaText}
            secondaryLink={product.secondaryCtaLink}
            secondaryLinkText={product.secondaryCtaText}
            features={product.features}
            images={product.images}
          />
        </div>
      ))
    ) : (
      <Row>
        {products.map(product => (
          <div className={s.products__card} key={product.name}>
            <ProductCard
              key={product.name}
              image={product.images[0]}
              heading={product.name}
              kicker={product.eyebrowText}
              text={_get(product, 'description.description')}
              price={showBulkPrice ? (product.bulkPrice || product.price) : product.price}
              priceSuffix={
                showBulkPrice ?
                  (product.bulkPriceSuffix || product.priceSuffix)
                :
                  product.priceSuffix
              }
              link={product.primaryCtaLink}
              ctaText={product.primaryCtaText}
            />
          </div>
        ))}
      </Row>
    )}
  </div>
);

Products.propTypes = {
  products: PropTypes.array,
  wideCardStyle: PropTypes.bool,
  showBulkPrice: PropTypes.bool,
};

export default Products;
